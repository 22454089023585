import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CSVLink } from 'react-csv'
import {
  Box,
  Flex,
  Button,
  Heading,
  Help,
  Progress,
  Tag,
  Result,
} from '@beachfront/ui'
import { useFormState, useField, FieldError } from '@beachfront/ui/forms'

import { SEGMENT_TYPE, SEGMENT_STATUS } from '../../../../enums'
import { UploadS3Input } from '../../../../components'
import { isNotEmptyArray } from '../../../../utils'
import { csvData, csvHeaders } from '../@constants'

const SegmentUploadForm = ({ isCreate }) => {
  const { values } = useFormState()
  const { input: status } = useField('status')
  const { input: uploadValue } = useField('uploadState')
  const [uploadingState, setUploadingState] = useState({ state: 'never' })

  const uploadMsg =
    values.dataType === SEGMENT_TYPE.IP.key
      ? 'Avails will become available after the segment has been active for at least 8 days.'
      : 'Please attach at least one ad to begin recording avails. Avails will become available after the segment has been active for at least 8 days.'

  const isFailed = values.status === SEGMENT_STATUS.VALIDATION_FAILED.key
  const isActive = values.status === SEGMENT_STATUS.ACTIVE.key
  const isProcessing =
    values.status === SEGMENT_STATUS.PROCESSING.key ||
    values.status === SEGMENT_STATUS.VALIDATION_PROCESSING.key ||
    values.status === SEGMENT_STATUS.VALIDATION_PENDING.key

  const showFileUpoader = () => {
    if (isProcessing) {
      return false
    }
    if (uploadingState.state === 'never' || uploadingState.state === 'failed') {
      return true
    }
    if (uploadingState.state !== 'uploading' && isActive) {
      return true
    }
    return false
  }

  const handleUploadState = (state) => {
    setUploadingState(state)
    if (state.state === 'success') {
      status.onChange(SEGMENT_STATUS.VALIDATION_PENDING.key)
      uploadValue.onChange(state)
    }
  }

  return (
    <Flex height='100%'>
      <Box m='auto' width='100%' textAlign='center'>
        {showFileUpoader() ? (
          <>
            <Heading level={4}>Upload Your CSV File Below</Heading>
            <Flex mb={3} justifyContent='center'>
              <Help status='warning'>
                The maximum file size should not exceed 50 MB. You can upload up
                to 100k records.
              </Help>
            </Flex>
            <Flex gap={2} justifyContent='center'>
              <Button type='link'>
                <CSVLink
                  data={csvData}
                  headers={csvHeaders}
                  uFEFF={false}
                  filename='sample-file.csv'
                >
                  Download Sample File
                </CSVLink>
              </Button>
              <UploadS3Input
                name='fileUrl'
                uploadType='csv'
                uploadFolder='clearvu/segment/upload'
                uploadState={handleUploadState}
                progressHide={true}
              />
            </Flex>
            <Flex justifyContent='center'>
              <FieldError mt={4} name='file' />
            </Flex>
            {uploadingState.state === 'failed' ? (
              <Flex mt={4} justifyContent='center'>
                <Help status='error'>
                  {uploadingState.ed ||
                    'Your file failed to upload. Please try again.'}
                </Help>
              </Flex>
            ) : null}
            {isFailed && isNotEmptyArray(values.errors) ? (
              <Flex mt={4} justifyContent='center'>
                <Help status='error'>
                  File can not be processed as it contains the following
                  keywords. Please contact your Account Manager for more
                  information.
                  <Flex my={2} gap={2} justifyContent='center' flexWrap='wrap'>
                    {values.errors.map((item, i) => (
                      <Tag key={i} color='error'>
                        {item}
                      </Tag>
                    ))}
                  </Flex>
                </Help>
              </Flex>
            ) : null}
            {values.canUpdate ? (
              <Flex mt={4} justifyContent='center'>
                <Help>Your current file was successfully processed.</Help>
              </Flex>
            ) : null}
          </>
        ) : null}
        {uploadingState.state === 'uploading' ? (
          <>
            <Heading level={4}>File Uploading In Progress</Heading>
            <Flex mt={3} justifyContent='center'>
              <Box>
                <Progress.Circle value={uploadingState.processPer || 0} />
              </Box>
            </Flex>
          </>
        ) : null}
        {uploadingState.state === 'success' || isProcessing ? (
          <>
            <Result
              status={isActive || isCreate ? 'success' : 'info'}
              title={
                isActive && !isCreate
                  ? 'Processing Successful'
                  : 'Upload Successful'
              }
            />
            <Flex mt={3} justifyContent='center'>
              <Help>
                {isActive && !isCreate
                  ? 'You can now use it to create Segments'
                  : uploadMsg}
              </Help>
            </Flex>
          </>
        ) : null}
      </Box>
    </Flex>
  )
}

SegmentUploadForm.propTypes = {
  isCreate: PropTypes.bool,
}

export default SegmentUploadForm
