import React, { useEffect, useRef, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { Field, useField } from '@beachfront/ui/forms'
import {
  Box,
  Flex,
  Grid,
  Card,
  Text,
  Tag,
  Input,
  FieldControl,
  Accordion,
} from '@beachfront/ui'
import isEqual from 'lodash/isEqual'

import { api } from '../../../client-api'
import { IS_DEALS_ENGINE } from '../../../constants'
import {
  PLATFORM,
  MEDIA_TYPE,
  MEDIA_PLAN_STATUS,
  MARGIN_TYPE,
} from '../../../enums'
import {
  useContentSize,
  useUserData,
  useTargetingChart,
  useTargetingWidgets,
  useMediaPlanCost,
} from '../../../hooks'
import { isNotEmptyArray, formatCurrency, omit } from '../../../utils'
import { SelectField } from '../../../components'
import { onPlatformChange, getTargetingParams } from '../@utils'

import { GeoPanel } from './geo-panel'
import { AppWebPanel } from './app-web-panel'
import { PublisherBuyerPanel } from './publisher-buyer-panel'
import { AccountPanel } from './account-panel'
import { ContentPanel } from './content-panel'
import { SegmentsPanel } from './segments-panel'

import { VirtualSelectField, IncludeExcludeField, TargetingWidgets } from '.'

const TargetingForm = ({
  availsDsp,
  values,
  initialValues,
  id,
  create,
  update,
  params,
  timezone,
  superAdmin,
  pmp,
  isDeal,
  dspDeal,
  showTitle,
  dld,
  publishers,
  adminCreate,
  adminUpdate,
  accountData,
  disableForm,
  setIsMediaPlanUpdate,
  onAvailsDspChange,
  onTimezoneChange,
  onChartFetch,
  onCostChange,
}) => {
  const { contentHeight } = useContentSize()
  const { pathname } = useLocation()
  const [user] = useUserData()
  const [selectedAccounts, setSelectedAccounts] = useState([])
  const [expandKeys, setExpandKeys] = useState([])
  const [chartType, setChartType] = useState('day')
  const [marginType, setMarginType] = useState(MARGIN_TYPE.FLAT.key)
  const [marginValue, setMarginValue] = useState(0)
  const [dealFloor, setDealFloor] = useState(0)
  const scrollRef = useRef()
  const theme = useTheme()

  const isPmp = !!pmp
  const isDsp = dspDeal || pathname.includes('/library')
  const disabled = !!superAdmin || disableForm
  const showDataFees = !!user.uploadedSegment || !!user.sharedSegment

  const { input: platform } = useField('platform')
  const { input: mediaType } = useField('mediaType')

  const targetingParams = useMemo(() => {
    const targetingValues = { ...values }

    if (availsDsp) {
      targetingValues.buyerId = availsDsp
    }

    return getTargetingParams(targetingValues, {
      dsp: isDsp,
      pmp: isPmp,
      publishers,
    })
  }, [values, isDsp, isPmp, publishers, availsDsp])

  const costQuery = useMediaPlanCost({
    params: {
      audienceBooleanLogic: values.audienceBooleanLogic,
      includeSegmentAnd: values.includeSegmentAnd === 'AND',
      includeSegments: values.includeSegments?.map((s) => s.id.toString()),
      excludeSegments: values.excludeSegments?.map((s) => s.id.toString()),
    },
    enabled: showDataFees,
  })

  const chartQuery = useTargetingChart({
    params: {
      chartType,
      pmp: isPmp,
      admin: superAdmin,
      mediaId: id,
      timezone,
      marginType,
      marginValue,
      dealFloor,
      dataCost: costQuery.data,
      targetingParams,
    },
    enabled: !costQuery.isFetching,
  })

  const widgetsQuery = useTargetingWidgets({
    params: {
      pmp: isPmp,
      admin: superAdmin,
      mediaId: id,
      timezone,
      availsDsp,
    },
  })

  const onPlatformClick = (platformKey, platformValue) => {
    const val = onPlatformChange(platformKey, platformValue)
    platform.onChange(val)
  }

  const onMediaTypeClick = (mediaTypeKey, mediaValues) => {
    if (mediaTypeKey !== mediaValues) {
      const val = [mediaTypeKey]
      mediaType.onChange(val)
    }
  }

  useEffect(() => {
    onCostChange?.(costQuery.data)
  }, [costQuery.data])

  useEffect(() => {
    const metrics =
      chartQuery.data?.graph?.summary ?? chartQuery.data?.chart?.summary
    const hasData =
      isNotEmptyArray(metrics) &&
      metrics.some((dt) => dt.data && dt.data !== '0' && dt.data !== '$0.0')
    onChartFetch?.(hasData)
  }, [chartQuery.data])

  useEffect(() => {
    if (isNotEmptyArray(accountData)) {
      setSelectedAccounts(accountData)
    }
  }, [accountData])

  useEffect(() => {
    const keys = ['includeSegments', 'excludeSegments', 'includeSegmentAnd']
    const newValues = omit(values, keys)
    const newInitialValues = omit(initialValues, keys)

    if (!isEqual(newValues, newInitialValues)) {
      setIsMediaPlanUpdate(true)
    } else {
      setIsMediaPlanUpdate(false)
    }
  }, [JSON.stringify(values)])

  if (superAdmin) {
    params = { ...params, mediaId: params.id }
  }

  if (params.id) {
    delete params.id
  }

  const height =
    dld || create || pmp ? contentHeight - 151 : contentHeight - 191

  const panelProps = {
    expandKeys,
    disabled,
    values,
    initialValues,
    params,
    id,
    isPmp,
    isDsp,
    isDeal,
    adminCreate,
    getPopupContainer: () => scrollRef.current,
  }

  return (
    <Grid
      width='100%'
      height={height}
      columns={[1, '1fr 1fr', '2fr 3fr']}
      gap={3}
    >
      <Card ref={scrollRef} width='100%' height='100%' overflow='auto'>
        {showTitle ? (
          <Field name='title' label={pmp ? 'Name' : 'Media Plan Name'}>
            <Input.TextArea
              placeholder='Enter name'
              onPressEnter={(e) => e.preventDefault()}
              autoSize={{ minRows: 1, maxRows: 3 }}
              disabled={disabled || (!create && !adminCreate && !adminUpdate)}
            />
          </Field>
        ) : null}
        {create ? (
          <Field name='status' label='Status'>
            <SelectField
              name='status'
              keyField='key'
              textField='name'
              data={MEDIA_PLAN_STATUS.values()}
              disabled={disabled}
            />
          </Field>
        ) : null}
        {showDataFees ? (
          <FieldControl
            label='Media Plan Data Fees (USD)'
            validateStatus={costQuery.error ? 'error' : 'default'}
          >
            <Flex
              px={2}
              width='100%'
              height={32}
              color='primary.base'
              bg='primary.0'
              border='base'
              borderColor='primary.2'
              borderRadius='base'
              alignItems='center'
              flexGrow={1}
            >
              <Text mx={1} strong>
                {formatCurrency(costQuery.data ?? 0)} CPM
              </Text>
            </Flex>
          </FieldControl>
        ) : null}
        <Grid columns={1}>
          <Box>
            <Field name='platform' label='Platform'>
              <Flex mt={1} gap={2} flexWrap='wrap'>
                {PLATFORM.values()
                  .filter(({ key }) => key !== PLATFORM.STB.key)
                  .map(({ key, name }) => (
                    <Tag
                      key={key}
                      color={
                        values.platform?.includes(key)
                          ? theme.colors.primary.base
                          : undefined
                      }
                      onClick={() =>
                        disabled ? null : onPlatformClick(key, values.platform)
                      }
                      style={{
                        cursor: disabled ? 'not-allowed' : 'pointer',
                      }}
                    >
                      {name}
                    </Tag>
                  ))}
              </Flex>
            </Field>
          </Box>
          <Box>
            <Field name='mediaType' label='Media Type'>
              <Flex mt={1} gap={2} flexWrap='wrap'>
                {MEDIA_TYPE.values().map(({ key, name }) => (
                  <Tag
                    key={key}
                    color={
                      values.mediaType?.includes(key)
                        ? theme.colors.primary.base
                        : undefined
                    }
                    onClick={() => (disabled ? null : onMediaTypeClick(key))}
                    style={{
                      cursor: disabled ? 'not-allowed' : 'pointer',
                    }}
                  >
                    {name}
                  </Tag>
                ))}
              </Flex>
            </Field>
          </Box>
          <VirtualSelectField
            allowClear
            mode='multiple'
            request={api.common.marketplaces}
            apiParams={params}
            values={values}
            initialValues={initialValues}
            disabled={disabled || dspDeal}
            getPopupContainer={() => scrollRef.current}
            emptyMsg='No marketplaces available'
            hideOnNoValues
            form={{
              name: 'marketplaceIds',
              keyField: 'key',
              textField: 'name',
              placeholder: 'Select custom marketplace',
              selectionName: 'marketplaceObj',
              label: 'Marketplace',
            }}
          />
          {IS_DEALS_ENGINE && !isPmp ? (
            <Box position='relative'>
              {!user.mediaPlanExclude ? (
                <Box
                  position='absolute'
                  height='32px'
                  right='-7px'
                  top='-7px'
                  className='customize-radio'
                >
                  <IncludeExcludeField name='excludeApp' disabled={disabled} />
                </Box>
              ) : null}
              <VirtualSelectField
                mode='multiple'
                allowClear
                request={api.common.app}
                apiParams={params}
                pagination={true}
                detail={id}
                values={values}
                disabled={disabled}
                initialValues={initialValues}
                getPopupContainer={() => scrollRef.current}
                emptyMsg='No Inventory Available'
                form={{
                  name: 'app',
                  textField: 'name',
                  keyField: 'key',
                  label: 'Inventory',
                  placeholder: 'Select Inventory',
                  selectionName: 'appObj',
                }}
              />
            </Box>
          ) : null}
        </Grid>
        <Accordion
          mt={3}
          type='multiple'
          value={expandKeys}
          onValueChange={setExpandKeys}
        >
          <GeoPanel {...panelProps} />
          <AppWebPanel
            {...panelProps}
            update={update}
            adminUpdate={adminUpdate}
          />
          <PublisherBuyerPanel {...panelProps} />
          {isPmp ? (
            <AccountPanel
              {...panelProps}
              selectedAccounts={selectedAccounts}
              onSelectedAccountsChange={setSelectedAccounts}
            />
          ) : null}
          <ContentPanel {...panelProps} />
          {user.uploadedSegment || user.sharedSegment ? (
            <SegmentsPanel {...panelProps} adminCreate={adminCreate} />
          ) : null}
        </Accordion>
      </Card>
      <Box width='100%' height='100%' overflow='auto'>
        <TargetingWidgets
          availsDsp={availsDsp}
          targetingParams={targetingParams}
          timezone={timezone}
          chartType={chartType}
          marginType={marginType}
          marginValue={marginValue}
          dealFloor={dealFloor}
          dataCost={costQuery.data}
          chartData={chartQuery.data}
          widgetsData={widgetsQuery.data}
          isChartLoading={chartQuery.isFetching || costQuery.isFetching}
          isWidgetsLoading={widgetsQuery.isFetching}
          onTimezoneChange={onTimezoneChange}
          onChartTypeChange={setChartType}
          onMarginTypeChange={setMarginType}
          onMarginValueChange={setMarginValue}
          onDealFloorChange={setDealFloor}
          onAvailsDspChange={onAvailsDspChange}
        />
      </Box>
    </Grid>
  )
}

TargetingForm.defaultProps = {
  values: {},
  initialValues: {},
  params: {},
  dspDeal: false,
  showTitle: true,
  adminCreate: false,
  publishers: false,
  isDeal: false,
  setIsMediaPlanUpdate: () => {},
}

TargetingForm.propTypes = {
  availsDsp: PropTypes.string,
  values: PropTypes.object,
  initialValues: PropTypes.object,
  onCostChange: PropTypes.func,
  id: PropTypes.string,
  update: PropTypes.bool,
  adminCreate: PropTypes.bool,
  adminUpdate: PropTypes.bool,
  accountData: PropTypes.array,
  create: PropTypes.bool,
  params: PropTypes.object,
  timezone: PropTypes.string,
  superAdmin: PropTypes.bool,
  pmp: PropTypes.bool,
  isDeal: PropTypes.bool,
  dspDeal: PropTypes.bool,
  showTitle: PropTypes.bool,
  dld: PropTypes.bool,
  publishers: PropTypes.bool,
  disableForm: PropTypes.bool,
  setIsMediaPlanUpdate: PropTypes.func,
  onTimezoneChange: PropTypes.func,
  onAvailsDspChange: PropTypes.func,
  onChartFetch: PropTypes.func,
}

export default TargetingForm
