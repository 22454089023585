import React from 'react'
import {
  DashboardOutlined,
  EyeOutlined,
  AuditOutlined,
  PieChartOutlined,
  MegaphoneOutlined,
  ApertureOutlined,
  DeploymentUnitOutlined,
  SolutionOutlined,
  TeamOutlined,
  CrownOutlined,
  QuestionCircleOutlined,
} from '@beachfront/ui/icons'

import { USER_ROLE } from '../../../enums'

export const menuItemConfig = [
  {
    icon: <DashboardOutlined />,
    key: 'dashboard',
    route: '/dashboard',
    title: 'Dashboard',
    roles: [USER_ROLE.AGENCY],
  },
  {
    icon: <EyeOutlined />,
    key: 'transparency',
    route: '/transparency',
    title: 'Transparency',
    roles: [],
  },
  {
    icon: <PieChartOutlined />,
    key: 'reach',
    route: '/reach',
    title: 'FTrackHHID Reach',
    roles: [],
  },
  {
    icon: <AuditOutlined />,
    key: 'deal',
    title: 'Deals',
    roles: [],
    submenu: [
      {
        key: 'library',
        route: '/deal/library',
        title: 'Deal Library',
      },
      {
        key: 'featured',
        route: '/deal/featured',
        title: 'Featured Deals',
      },
    ],
  },
  {
    icon: <AuditOutlined />,
    key: 'deals',
    title: 'Deals',
    roles: [USER_ROLE.AGENCY],
    submenu: [
      {
        key: 'deals',
        route: '/deals/self',
        title: 'Self Created',
      },
      {
        key: 'library',
        route: '/deals/library',
        title: 'Deal Library',
      },
    ],
  },
  {
    icon: <MegaphoneOutlined />,
    key: 'mediaplan',
    route: '/mediaplan',
    title: 'Media Plans',
    roles: [USER_ROLE.AGENCY],
  },
  {
    icon: <ApertureOutlined />,
    key: 'segment',
    title: 'Segments',
    roles: [USER_ROLE.AGENCY],
    submenu: [
      {
        key: 'uploaded-segments',
        route: '/segment/upload',
        title: 'Uploaded Segments',
      },
      {
        key: 'shared-segments',
        route: '/segment/shared',
        title: 'Shared Segments',
      },
    ],
  },
  {
    icon: <DeploymentUnitOutlined />,
    key: 'medialist',
    title: 'Media Lists',
    roles: [],
    submenu: [
      {
        key: 'bundle',
        route: '/medialist/bundle',
        title: 'Bundles',
      },
      {
        key: 'domain',
        route: '/medialist/domain',
        title: 'Domains',
      },
      {
        key: 'zipcode',
        route: '/medialist/zipcode',
        title: 'Zip Codes',
      },
      {
        key: 'metrocode',
        route: '/medialist/metrocode',
        title: 'Metro Codes',
      },
    ],
  },
  {
    icon: <SolutionOutlined />,
    key: 'report',
    title: 'Reporting',
    roles: [USER_ROLE.AGENCY],
    submenu: [
      {
        key: 'owned',
        route: '/reports/owned',
        title: 'Owned Reports',
      },
      {
        key: 'shared',
        route: '/reports/shared',
        title: 'Shared Reports',
      },
    ],
  },
  {
    icon: <TeamOutlined />,
    key: 'users',
    route: '/users',
    title: 'Users',
    roles: [USER_ROLE.ADMIN],
  },
  {
    icon: <CrownOutlined />,
    key: 'admin',
    title: 'Super Admin',
    roles: [USER_ROLE.SUPER],
    submenu: [
      {
        key: 'admin-account',
        route: '/admin/account',
        title: 'Accounts',
      },
      {
        key: 'admin-user',
        route: '/admin/user',
        title: 'Users',
      },
      {
        key: 'admin-meta',
        route: '/admin/meta',
        title: 'Meta',
      },
      {
        key: 'admin-supplyrequest',
        route: '/admin/supplyrequest',
        title: 'Supply Requests',
      },
      {
        key: 'admin-dsp',
        route: '/admin/dsp',
        title: 'DSP',
      },
      {
        key: 'system-messages',
        route: '/admin/system-messages',
        title: 'System Messages',
      },
      {
        key: 'restricted-publishers',
        route: '/admin/restricted-publishers',
        title: 'Restricted Publishers',
      },
    ],
  },
  {
    icon: <CrownOutlined />,
    key: 'deals-admin',
    title: 'Admin',
    submenu: [
      {
        key: 'admin-dashboard',
        route: '/admin/dashboard',
        title: 'Dashboard',
      },
      {
        key: 'admin-deals',
        route: '/admin/deals',
        title: 'Deals',
      },
      {
        key: 'admin-mediaplan',
        route: '/admin/mediaplan',
        title: 'Media Plans',
      },
      {
        key: 'admin-availsexplorer',
        route: '/admin/avails-explorer',
        title: 'Avails Explorer',
      },
      {
        key: 'admin-system-message',
        route: '/admin/system-messages',
        title: 'System Messages',
      },
    ],
  },
  {
    icon: <QuestionCircleOutlined />,
    title: 'Help',
    key: 'support',
    route: '/helpcenter',
  },
]
